import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { customStyles, styles } from './styles'
import { getBotLanguages } from '../../tabs/settings/api/settings'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { LanguageArrowDown } from '../icons/LanguageArrowDown'
import { LanguageArrowUp } from '../icons/LanguageArrowUp'

const LanguageSelector = props => {
  const { activeBotId, onChange, value, classes, defaultValue, styles, predefineValue } = props
  const [activeLanguages, setActiveLanguages] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLanguageOption, setSelectedLanguageOption] = useState({ label: 'Dutch', value: 'nl' })

  useEffect(() => {
    if (activeBotId) {
      getBotLanguages(activeBotId).then(botLanguages => {
        if (botLanguages) {
          const { defaultLanguage, activeLanguages } = botLanguages
          const selectedOption = {
            label: defaultLanguage.fullName,
            value: defaultLanguage.shortName,
            rtl: defaultLanguage.isRtl,
            id: defaultLanguage.id,
          }

          const languageOptions = activeLanguages.map(language => {
            return {
              label: language.fullName,
              value: language.shortName,
              rtl: language.isRtl,
              id: language.id,
            }
          })

          if (defaultValue) {
            languageOptions.unshift(defaultValue)
          } else if (predefineValue) {
            onChange(selectedOption)
          }

          setActiveLanguages(languageOptions)
          setSelectedLanguageOption(selectedOption)
        }
      })
    }
  }, [activeBotId])

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        {props.children}
        <span className={classes.arrow}>{isOpen ? <LanguageArrowUp /> : <LanguageArrowDown />}</span>
      </components.SingleValue>
    )
  }

  return (
    <Select
      options={activeLanguages}
      value={value || selectedLanguageOption}
      styles={styles || customStyles}
      isSearchable={false}
      onChange={onChange}
      components={styles ? {} : { SingleValue }}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
    />
  )
}

LanguageSelector.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBotId: state.activeBot?.id,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(LanguageSelector)))
