import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Heading from '../../../../../uiKit/texts/Heading'
import Paragraph from '../../../../../uiKit/texts/Paragraph'
import { DownloadIcon } from '../../../../../uiKit/icons/DownloadIcon'
import ImportButton from '../../../../../uiKit/ImportButton/ImportButton'
import UpdateBotModal from '../UpdateBotModal/UpdateBotModal'
import { exportBot } from '../../../api/export'

import { styles } from './styles'
import { alertError, alertSuccess } from '../../../../../api'
import LoaderOverlay from '../../../../../uiKit/loaders/loaderOverlay'

const baseUserImage = '/images/platform/default-user-icon.png'

const VersionBlock = ({ classes, activeBot }) => {//NOSONAR
  const [openUpdateBotModal, setOpenUpdateBotModal] = useState(false)
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleExportBot = () => {
    setLoading(true)
    exportBot(activeBot.id)
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${activeBot.name}_${activeBot?.contentVersion || '1.0'}.zip`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        alertSuccess('The bot has been exported successfully')
      })
      .catch(error => alertError(error?.message || 'An issue has occurred during bot export. Please, ping support.'))
      .finally(() => setLoading(false))
  }

  const handleUploadBot = event => {
    const file = event.target.files[0]

    if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
      const formData = new FormData()
      formData.append('file', file)
      setFormData(formData)

      if (event.target.files.length) {
        setOpenUpdateBotModal(true)
      }
    } else {
      alertError('Please, choose .zip file')
    }
  }

  const handleUpdateBotModal = openUpdateBotModal => {
    setOpenUpdateBotModal(openUpdateBotModal)
    if (!openUpdateBotModal) {
      setFormData(null)
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Heading>Content version</Heading>
        <Paragraph>Version name:</Paragraph>
        <div className={classes.text}>
          {activeBot.name} {activeBot?.contentVersion || '1.0'}
          <span onClick={handleExportBot}>{loading ? <LoaderOverlay showLoader={loading} /> : <DownloadIcon />}</span>
        </div>

        {activeBot?.updatedAt && (
          <>
            <Paragraph>Last uploaded:</Paragraph>
            <div className={classes.text}>
              {moment(activeBot?.updatedAt)
                .utcOffset(activeBot.timezone * 60 + moment().utcOffset())
                .format('ddd DD MMM, YYYY hh:mm a')}
            </div>
          </>
        )}

        {activeBot?.updatedByName && (
          <>
            <Paragraph>Uploaded by:</Paragraph>
            <div className={classes.userNameContainer}>
              <img className={classes.userImage} src={activeBot?.updatedByImageUrl || baseUserImage} alt="user image" />
              <div className={classes.text}>{activeBot?.updatedByName}</div>
            </div>
          </>
        )}

        <ImportButton onChange={handleUploadBot} />
      </div>

      <UpdateBotModal open={openUpdateBotModal} formData={formData} onClose={() => handleUpdateBotModal(false)} />
    </>
  )
}

VersionBlock.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(VersionBlock))
